<template>

  <Menu ref="menu1" :popup="true" :model="items"></Menu>

  <TabView lazy v-model:activeIndex="active" @tab-click="onInsightTabClick($event.index)" scrollable>
  
  <TabPanel header="News">
  
  <div class="flex flex-column md:flex-row align-items-left w-full">
  <!-- <div> -->
    <div class="flex-1 text-left md:text-left">

      <div class="flex align-items-center justify-content-between">
        <div>
          <!-- <router-link :to="{name:caller,params:{code:layout+'a0'}}"> -->
            <!-- <i class="pi pi-tags mr-1"></i> -->
          <!-- </router-link> -->
          <!-- <router-link class="mr-1" :to="{name:caller,params:{code:layout+'s'+newsData.news_sentiment}}"> -->
            <!-- <span :class="'sentiment-badge status-' + getBadgeStatusText(newsData.news_sentiment)">{{getFaceSymbol(newsData.news_sentiment)}}</span> -->
          <!-- </router-link> -->
          <Chip class="chip-r" :class="getChipClass(0)">
            <!-- <a href="#" @click.prevent.stop="onAllClick()"> -->
              <span :class="getChipTextClass(0)">All</span>
            <!-- </a> -->
          </Chip>
          <Chip class="chip-r" :class="getChipClass(newsData.news_type)">
            <!-- <router-link :to="{name:caller,params:{code:layout+'t'+newsData.news_type}}"> -->
              <span :class="getChipTextClass(newsData.news_type)">{{newsData.news_type}}</span>
            <!-- </router-link> -->
          </Chip>
          <template v-for="(tag, index) in newsData.news_tags" :key="index">
            <Chip class="chip-r" :class="getChipClass(tag)">
              <!-- <router-link :to="{name:caller,params:{code:layout+'z'+tag}}"> -->
                <span :class="getChipTextClass(tag)">{{tag}}</span>
              <!-- </router-link> -->
            </Chip>
          </template>
        </div>
        <Button icon="pi pi-ellipsis-v" class="p-button-secondary p-button-outlined h-2rem w-2rem" @click="toggle_setMeigaras($event, newsData.meigaras)"></Button>
      </div>

      <div><span class="mr-1 subinfo-span"> {{newsData.news_datetime}} {{newsData.info_source}} {{newsData.info_source2}}</span></div>
      <div class="text-1xl mb-1">
        <a v-if="newsData.news_sentiment != 'U'" class="mr-1">
          <span :class="'sentiment-badge status-' + getBadgeStatusText(newsData.news_sentiment)">{{getFaceSymbol(newsData.news_sentiment)}}</span>
				</a>
        <a v-bind:href="newsData.news_url" target="_blank">{{newsData.news_overview}}</a>
      </div>
      <div class="mb-1">
        <template v-for="(meigara, index) in newsData.meigaras" :key="index">
          <Chip class="chip-r mr-1" :class="getChipClass(meigara.meigara_cd)">
            <!-- <router-link :to="{name:caller,params:{code:layout+'m'+meigara.meigara_cd}}"> -->
              <span class="mr-1" :class="getChipTextClass(meigara.meigara_cd)">{{meigara.market_name_short}}</span>
              <span class="mr-1" :class="getChipTextClass(meigara.meigara_cd)">{{meigara.meigara_cd}} {{meigara.meigara_name}}</span>											
              <span class="mr-1" :class="getChipTextClass(meigara.meigara_cd)">¥{{meigara.close}}</span> 
              <span :class="getPriceClass(meigara.v1d_mean)">{{meigara.v1d_mean}}%</span>
            <!-- </router-link> -->
          </Chip>
          <table>
            <tr>
              <td class="rotate_text" vAlign="bottom">
                <span class="date-span">{{getTrendchartDate(meigara.meigara_trendchart_sdate)}}</span>
              </td>
              <td>
                <!-- <router-link :to="{name:'meigarachart',params:{code:meigara.meigara_cd}}"> -->
                  <img class="trendchart-img" :src="'https://tradersr.com' + meigara.meigara_trendchart_file"/>
                <!-- </router-link> -->
              </td>
              <td class="rotate_text"  vAlign="top" style="padding:0">
                <span class="date-span">{{getTrendchartDate(meigara.meigara_trendchart_edate)}}</span>
              </td>
            </tr>
          </table>
        </template>
      </div>
      <div class="flex align-items-center justify-content-between"> 
        <span class="subinfo-span">株価@{{newsData.odate}} {{newsData.otime}}</span>
        <span class="subinfo-span">{{newsData.meigaras_cnt}}銘柄 
          <!-- <i class="pi pi-copy" @click="copyToClipboard(newsData.meigaras)"></i> -->
        </span>
      </div>
    </div>
  </div>

  </TabPanel>

  <TabPanel header="関係R">
  
    meigaracompare {{perf}}
          <DataTable :value="relations" class="p-datatable-sm"
					responsiveLayout="scroll" showGridlines stripedRows
          :paginator="true" :rows="50"
          paginatorTemplate="FirstPageLink PrevPageLink NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50,100]"
          currentPageReportTemplate="{first}~{last} of {totalRecords}"
					selectionMode="single"
          v-model:expandedRows="expandedRows"
          :loading="loading">
          <Column :expander="true" headerStyle="width: 3rem" />
          <Column field="meigaras_cnt" header="銘柄数"></Column>
          <Column field="meigaras_cnt" header="関係率" :sortable="true">
            <template #body="slotProps">
              <!-- <div :class="v1dmean_priceClass(slotProps.data.meigaras_cnt)"> -->
              <div>
                {{Math.round((slotProps.data.meigaras_cnt/newsData.meigaras_cnt)*100)}}%
              </div>
            </template>
          </Column>
					<Column field="rname" header="R名称" :sortable="true"></Column>           
					<Column field="rtype" header="Rタイプ" :sortable="true"></Column>
					<Column field="rtypename" header="Rタイプ名" :sortable="true"></Column>
          <Column field="rdate" header="日付" :sortable="true"></Column>     
          <Column field="rtime" header="時刻" :sortable="true"></Column>
          <template #expansion="slotProps">
            <div class="industrytrend-subtable">
              <h5>R名称：{{slotProps.data.rname}}</h5>
              <DataTable :value="slotProps.data.meigaras" responsiveLayout="scroll"
                selectionMode="single">
                <Column field="market_name_short" header="市場" sortable></Column>
                <Column field="meigara_cd" header="コード" sortable></Column>
                <Column field="meigara_name" header="銘柄" sortable></Column>
                <Column field="settlement_date" header="決算日" sortable></Column>
                <Column field="close" header="close" sortable></Column>
                <Column field="ago_today_diff" header="atdiff" sortable></Column>
                <Column field="ago_today_ratio" header="atratio" sortable></Column>
                <Column field="per" header="per" sortable></Column>
                <Column field="pbr" header="pbr" sortable></Column>
                <Column field="market_value_str" header="時価総額"></Column>
                <Column field="market_value" header="mvalue" sortable></Column>
                <Column field="price_date" header="date" sortable></Column>
                <Column field="price_timestamp" header="time" sortable></Column>
              </DataTable>
            </div>
          </template>
				</DataTable>
  
  </TabPanel>

  <TabPanel header="類似性">
    類似性
  </TabPanel>
  
  <TabPanel header="相関係数">
    相関係数
  </TabPanel>

  </TabView>

</template>

<script>
import axios from 'axios';

export default {
  inject: ['dialogRef'],
  data() {
      return {
        active: 0,
        perf: null,
        loading: false,
        relations: null,
        newsData: null,
        selectedCodeKey: null,
        activeTab: 0,
        copiedMeigaras: 0,
        expandedRows: [],
        layout: null,
        caller: null,
        selectedNewsId: null,
				items: [
					{
						label: '銘柄をコピー',
						icon: 'pi pi-copy',
						command: () => {
							// this.$router.push('/meigarasearch_ext/' + this.selectedBookmarkCd);
							this.copyToClipboard(this.copiedMeigaras);
						}
					},
					{
						label: '前日比で昇順',
						icon: 'pi pi-sort-numeric-up-alt',
						command: () => {
							// console.log(this.selectedNewsId);
							this.sortMeigaras(0, this.selectedNewsId);
						}
					},
					{
						label: '前日比で降順',
						icon: 'pi pi-sort-numeric-down-alt',
						command: () => {
							// console.log(this.selectedNewsId);
							this.sortMeigaras(1, this.selectedNewsId);
						}
					},
				],
      }
  },
  created() {
    console.log('News Created');
    if (this.dialogRef) {
        this.newsData = this.dialogRef.data.newsData;
        this.selectedCodeKey = this.dialogRef.data.selectedCodeKey;
        this.layout = this.dialogRef.data.layout;
        this.caller = this.dialogRef.data.caller;

        console.log(this.layout);
    }
    console.log(this.newsData);
    console.log(this.selectedCodeKey);
  },
  mounted() {
      // console.log('News Mounted');
      // if (this.dialogRef) {
      //     this.newsData = this.dialogRef.data.newsData;
      // }
      // console.log(this.newsData);
  },
  methods: {
    getPriceClass(data) {
      return [
        {
          'positive': data > 0,
          'negative': data < 0,
          'neutral': data == 0,
        }
      ];
    },
    getChipClass(key){
      return [
        {'a-active':this.selectedCodeKey===key},
      ]
    },
    getChipTextClass(key){
      return [
        {'text-active':this.selectedCodeKey===key},
      ]
    },
    gotoMeigarachart(event, mcd) {
      this.$router.push('/meigarachart/' + mcd);
      event.preventDefault();
    },
    getTrendchartDate(param) {
      let tcdate = null;
      if(param != null && param.length == 10){
        tcdate = param.substr(5, 5);
        tcdate = tcdate.replace('-','/');
      }
      return tcdate;
		},
    getBadgeStatusText(key){
      let ret = null;

      if(this.selectedCodeKey===key){
        ret = 'active';
      }else{
        ret = key.toLowerCase();
      }

      return ret;
    },
    getFaceSymbol(sentiment) {
      let ret = null;

      if(sentiment == 'P'){
        ret = '好';
      }else if(sentiment == 'N'){
        ret = '悪';
      }else if(sentiment == 'B'){
        ret = '？';
      }else{
        ret = '未';
      }

      return ret;
    },
    toggle_setMeigaras(event, meigaras){
      this.$refs.menu1.toggle(event);
      this.copiedMeigaras = meigaras;
    },
    copyToClipboard(meigaras){
      // console.log(meigaras);
      this.clipboardText = '';
      for(let i=0; i<meigaras.length; i++) {
        if(i != meigaras.length-1){
          this.clipboardText += meigaras[i].meigara_cd + ", ";
        }else{
          this.clipboardText += meigaras[i].meigara_cd;
        }
      }
      // console.log(this.clipboardText);
      navigator.clipboard.writeText(this.clipboardText);
      // console.log("copied");
      this.$toast.add({severity:'success', summary: '銘柄コピー', detail:'クリップボードに' + meigaras.length + '銘柄をコピーしました (' + this.clipboardText + ')', life: 2000});
    },
    onInsightTabClick(tabIndex) {
      if(tabIndex == 1){ // R tab
        let meigaras = this.newsData.meigaras;
        let meigaraslist = [];

				for(let i=0; i<meigaras.length; i++) {
					meigaraslist.push(meigaras[i].meigara_cd);
				}

        if(meigaraslist && meigaraslist.length > 0){
          console.log(meigaraslist);
          this.loading = true;
          axios.post('https://api.tradersr.com/api/meigaracompare', {mode:2, meigaralist:meigaraslist})
          .then(response => {
            console.log(response);
            this.relations = response.data.data;
            this.perf = response.data.perf;
            this.loading = false;
          })
        }
      }
    },
    sortMeigaras(sortOption, newsId){
      console.log(sortOption);
      console.log(newsId);
      
      // let idx = this.findNewsIndex(newsId);
      let meigaras = this.newsData.meigaras;

      if(sortOption == 0){
        meigaras.sort((a, b) => {
          return a.v1d_mean < b.v1d_mean ? -1 : 1;
        });
      }else{
        meigaras.sort((a, b) => {
          return a.v1d_mean > b.v1d_mean ? -1 : 1;
        });
      }

      this.newsData.meigaras = meigaras;
    },
    // findNewsIndex(newsId){
    //   let idx = null;
    //   for (let i=0; i<newsData.length; i++){
    //     if(this.newsData[i].id == newsId){
    //       idx = i;
    //       break;
    //     }
    //   }
    //   return idx;
    // }
  }
}
</script>

<style scoped lang="scss">
	@import '../assets/prod/styles/badges.scss';
	@import '../assets/prod/styles/prices.scss';

  .chip-r {
    margin: 2px
  }
	a {
    color: #515c66;
    // color: #ffffff;
  }
  a:hover {
    color: #4291dc;
    // text-decoration:underline;
  }
  .a-active {
    background-color: rgba(47, 142, 229, 0.4);
  }
  .text-active {
    color: #ffffff;
  }
  i:hover {
		color: #4291dc;
		cursor: pointer;
	}
  .subinfo-span {
		// color: rgba(81, 92, 102, 0.6);
		font-size: 12px;
	}
  .img_link {
		cursor: pointer;
	}
  .trendchart-img {
		opacity: 0.8;
		// border: solid 1px #cccccc;
		border-radius: 5px;  
		width: 182px;
		height: 47px;
	}
  .rotate_text {
    transform:rotate(-90deg);
    // width: 10px;
    // height: 1px;
  }
  // table {
  //   border: 1;
  // }
  // th, td {
  //   padding-top: 0px;
  //   padding-bottom: 0px;
  //   padding-left: 0px;
  //   padding-right: 0px;
  // }
  .date-span {
    color: rgba(81, 92, 102, 0.6);
    font-size: 11px;    
  }
</style>