<template>
	<div class="grid">
		<Toast :breakpoints="{'920px': {width: '100%', right: '0', left: '0'}}" />
		<Menu ref="menu1" :popup="true" :model="items"></Menu>
		<div>
				<DynamicDialog />
			</div>
		<div class="col-12">
			{{perf}}:{{lastload_type}}:{{this.lastload_codekey}} (loadall {{lastload_incrementalDone}}) - {{clickedObject}} {{ selectedCodeKey }} - incrementalCount:{{ incrementalCount }} / incrementalDone:{{ incrementalDone }} / clickedObject:{{ clickedObject }}
			/ offset_dt_sent: {{ this.offset_dt_sent }} / lastnews_datetime: {{ lastnews_datetime }}
			<!-- <div class="card"> -->
				<!-- meigaranewsrecentl {{perf}}
				<br>codekey {{selectedCodeKey}} -->
				<DataView :value="dataviewValue" :layout="layout" :paginator="true" :rows="8" :sortOrder="sortOrder" :sortField="sortField"
				paginatorTemplate="RowsPerPageDropdown PrevPageLink FirstPageLink CurrentPageReport LastPageLink NextPageLink" :rowsPerPageOptions="[1,8,12,40,100]"
				currentPageReportTemplate="{currentPage}/{totalPages}ページ"
				paginatorPosition="both">
					<!-- <template #paginatorLeft /> -->
					<!-- <template #header>
						<div class="grid grid-nogutter">
							<div class="col-6 text-left">
									<Button label="更新" icon="pi pi-refresh" class="p-button-sm" @click="onRefreshButtonClick" :loading="loading"/>
							</div>
							<div class="col-6 text-right">
								<DataViewLayoutOptions v-model="layout" />
							</div>
						</div>
					</template> -->
					<template #paginatorstart>
						<!-- <Button icon="pi pi-refresh" class="p-button-sm" @click="onRefreshButtonClick" :loading="loading"/> -->
						<SplitButton icon="pi pi-sync" label="ニュース更新" :model="button_items" class="p-button-sm mr-1 mb-1" @click="onRefreshButtonClick" :loading="loading"/>
						<DataViewLayoutOptions v-model="layout"/>
						<!-- <i class="pi pi-filter-fill"></i> -->
						<!-- <Chip v-if="lastload_type == 'a'" class="chip-r chip-highlight3">
							直近
						</Chip> -->
						<Chip v-if="recent_flag == true" class="chip-r chip-highlight3 mt-1">
							<!-- <i class="pi pi-globe mr-1"></i> -->
							直近
						</Chip>
						<Chip v-else class="chip-r chip-highlight3">
							<!-- <i class="pi pi-globe mr-1"></i> -->
							<!-- {{lastload_codekey}} -->
							<span v-if="lastload_type == 's'">
								<span v-if="lastload_codekey == 'P'">好</span>
								<span v-else-if="lastload_codekey == 'N'">悪</span>
								<span v-else-if="lastload_codekey == 'B'">好/悪</span>
							</span>
							<span v-else>
								{{lastload_codekey}}
							</span>
						</Chip>
						<!-- <Chip v-if="lastload_type == 'a' && selectedCodeKey != 0" class="chip-r chip-highlight3">
							{{selectedCodeKey}}
						</Chip> -->
						<Chip v-if="selectedCodeKey != 0 && lastload_codekey != selectedCodeKey" class="chip-r chip-highlight3">
							<span v-if="clickedObject == 'sentiment'">
								<span v-if="selectedCodeKey == 'P'">好</span>
								<span v-else-if="selectedCodeKey == 'N'">悪</span>
								<span v-else-if="selectedCodeKey == 'B'">好/悪</span>
							</span>
							<span v-else>
								{{selectedCodeKey}}
							</span>
						</Chip>
					</template>
					<!-- <template #paginatorend>
						<DataViewLayoutOptions v-model="layout" />
					</template> -->
					<template #list="slotProps">					
						<div class="col-12">
							<div v-if="slotProps.data.data_type == 'news'" class="flex flex-column md:flex-row align-items-left p-3 w-full">
								<div class="flex-1 text-left md:text-left">
									<div class="flex align-items-center justify-content-between">
										<div>
											<!-- <a href="#" @click.prevent.stop="onAllClick()">
												<i class="pi pi-tags mr-1"></i>
											</a> -->
											<!-- <a href="#" @click.prevent.stop="onSentimentClick(slotProps.data.news_sentiment)">
												<span :class="'sentiment-badge status-' + getBadgeStatusText(slotProps.data.news_sentiment)">{{getFaceSymbol(slotProps.data.news_sentiment)}}</span>
											</a> -->
											<Chip class="chip-r" :class="getChipClass('All')">
												<a href="#" @click.prevent.stop="onAllClick()">
													<span :class="getChipTextClass('All')">All</span>
												</a>
											</Chip>
											<Chip class="chip-r" :class="getChipClass(slotProps.data.news_type)">
												<a href="#" @click.prevent.stop="onNewsTypeClick(slotProps.data.news_type)">
													<span :class="getChipTextClass(slotProps.data.news_type)">{{slotProps.data.news_type}}</span>
												</a>
											</Chip>
											<template v-for="(tag, index) in slotProps.data.news_tags" :key="index">
												<Chip class="chip-r" :class="getChipClass(tag)">
													<a href="#" @click.prevent.stop="onTagClick(tag)">
														<span :class="getChipTextClass(tag)">{{tag}}</span>
													</a>
												</Chip>
											</template>
										</div>

										<Button icon="pi pi-ellipsis-v" class="p-button-secondary p-button-outlined h-2rem w-2rem" @click="toggle_setMeigaras($event, slotProps.data.meigaras, slotProps.data.id)"></Button>
									</div>
									<div><span class="mr-1 subinfo-span">{{slotProps.data.news_datetime}} {{slotProps.data.info_source}} {{slotProps.data.info_source2}}</span></div>
									<div class="text-1xl mb-1">
										<a v-if="slotProps.data.news_sentiment != 'U'" class="mr-1" href="#" @click.prevent.stop="onSentimentClick(slotProps.data.news_sentiment)">
											<span :class="'sentiment-badge status-' + getBadgeStatusText(slotProps.data.news_sentiment)">{{getFaceSymbol(slotProps.data.news_sentiment)}}</span>
										</a>
										<a v-bind:href="slotProps.data.news_url" target="_blank">{{slotProps.data.news_overview}}</a>
									</div>
									<div class="mb-1">
										<template v-for="(meigara, index) in slotProps.data.meigaras" :key="index">
											<Chip class="chip-r mr-1" :class="getChipClass(meigara.meigara_cd)">
												<img class="img_link" :src="'https://tradersr.com' + meigara.meigara_trendchart_file" @click="gotoMeigarachart($event, meigara.meigara_cd)"/>
												<a href="#" @click.prevent.stop="onMeigaraClick(meigara.meigara_cd)">
													<span class="mr-1" :class="getChipTextClass(meigara.meigara_cd)">{{meigara.market_name_short}}</span>
													<span class="mr-1" :class="getChipTextClass(meigara.meigara_cd)">{{meigara.meigara_cd}} {{meigara.meigara_name}}</span>											
													<span class="mr-1" :class="getChipTextClass(meigara.meigara_cd)">¥{{meigara.close}}</span> 
													<span :class="getPriceClass(meigara.v1d_mean)">{{meigara.v1d_mean}}%</span>
												</a>
											</Chip>
										</template>
									</div>
									<!-- <a href="#" @click.prevent.stop="onShow(slotProps.data)"><span class="span-highlight">...more</span></a> -->
									<div class="flex align-items-center justify-content-between"> 
										<span class="subinfo-span">株価@{{slotProps.data.odate}} {{slotProps.data.otime}}</span>
										<a href="#" @click.prevent.stop="onShow(slotProps.data)">
											<span class="subinfo-span">
												{{slotProps.data.meigaras_cnt}}銘柄 
												<i class="pi pi-window-maximize"></i>
											</span>
										</a>
									</div>
								</div>
							</div>

							<div v-else-if="slotProps.data.data_type == 'dummy'" class="card m-1 border-0" align="center">
									キーワード
									<Chip class="chip-r chip-highlight">
										<span v-if="clickedObject == 'sentiment'">
											<span v-if="selectedCodeKey == 'P'">好</span>
											<span v-else-if="selectedCodeKey == 'N'">悪</span>
											<span v-else-if="selectedCodeKey == 'B'">好/悪</span>
										</span>
										<span v-else>
											{{selectedCodeKey}}
										</span>
									</Chip>

									<DeferredContent @load="onDataLoad">
										<div v-if="clickedObject != 'all'">
											<div v-if="incrementalDone == 'false'">
												<Button label="ニュースをもっと見る" icon="pi pi-refresh" :model="button_items" class="p-button-text" @click="getIncrementalNews" :loading="loading_more"/>
											</div>
											<div v-if="incrementalDone == 'true'">
												<span class="subinfo-span">End of News</span>
											</div>
										</div>
									</DeferredContent>
							</div>

						</div>

					</template>

					<template #grid="slotProps">											
						<div class="col-12 md:col-3">

							<div v-if="slotProps.data.data_type == 'news'" class="card m-1 border-0">
								<div class="flex align-items-center justify-content-between">
									<div>
										<!-- <a href="#" @click.prevent.stop="onAllClick()">
											<i class="pi pi-tags mr-1"></i>
										</a> -->
										<Chip class="chip-r" :class="getChipClass('All')">
												<a href="#" @click.prevent.stop="onAllClick()">
													<span :class="getChipTextClass('All')">All</span>
												</a>
											</Chip>
										<Chip class="chip-r" :class="getChipClass(slotProps.data.news_type)">
											<a href="#" @click.prevent.stop="onNewsTypeClick(slotProps.data.news_type)">
												<span :class="getChipTextClass(slotProps.data.news_type)">{{slotProps.data.news_type}}</span>
											</a>
										</Chip>
										<template v-for="(tag, index) in slotProps.data.news_tags" :key="index">
											<Chip class="chip-r" :class="getChipClass(tag)">
												<a href="#" @click.prevent.stop="onTagClick(tag)">
													<span :class="getChipTextClass(tag)">{{tag}}</span>
												</a>
											</Chip>
										</template>
									</div>
									<Button icon="pi pi-ellipsis-v" class="p-button-secondary p-button-outlined h-2rem w-2rem" @click="toggle_setMeigaras($event, slotProps.data.meigaras, slotProps.data.id)"></Button>
								</div>
								<div class="text-left">
									<div>
										<span class="ml-1 mr-1 subinfo-span">{{slotProps.data.news_datetime}} {{slotProps.data.info_source}} {{slotProps.data.info_source2}}</span>
									</div>
									<div class="text-1xl mb-1 align-items-center">
										<a v-if="slotProps.data.news_sentiment != 'U'" class="mr-1" href="#" @click.prevent.stop="onSentimentClick(slotProps.data.news_sentiment)">
											<span :class="'sentiment-badge status-' + getBadgeStatusText(slotProps.data.news_sentiment)">{{getFaceSymbol(slotProps.data.news_sentiment)}}</span>
										</a>
										<a v-bind:href="slotProps.data.news_url" target="_blank">{{slotProps.data.news_overview}}</a>
									</div>
                  <div>
										<Chip class="chip-r mr-1 mb-1" :class="getChipClass(slotProps.data.meigaras[0].meigara_cd)">
											<a href="#" @click.prevent.stop="onMeigaraClick(slotProps.data.meigaras[0].meigara_cd)">
												<span class="mr-1" :class="getChipTextClass(slotProps.data.meigaras[0].meigara_cd)">{{slotProps.data.meigaras[0].market_name_short}}</span>
												<span :class="getChipTextClass(slotProps.data.meigaras[0].meigara_cd)">{{slotProps.data.meigaras[0].meigara_cd}} {{slotProps.data.meigaras[0].meigara_name}}</span>
												<span class="ml-1 mr-1" :class="getChipTextClass(slotProps.data.meigaras[0].meigara_cd)">¥{{slotProps.data.meigaras[0].close}}</span> 
												<span :class="getPriceClass(slotProps.data.meigaras[0].v1d_mean)">{{slotProps.data.meigaras[0].v1d_mean}}%</span>
											</a>
										</Chip>
										<span v-if="slotProps.data.meigaras_cnt > 1">
											<a href="#" @click.prevent.stop="onShow(slotProps.data)"><span class="span-highlight">...more</span></a>
										</span>
									</div>
									<table border="0">
										<tr>
											<td class="rotate_text" vAlign="bottom">
												<span class="date-span">{{getTrendchartDate(slotProps.data.meigaras[0].meigara_trendchart_sdate)}}</span>
											</td>
											<td>
												<router-link :to="{name:'meigarachart',params:{code:slotProps.data.meigaras[0].meigara_cd}}">
													<img class="trendchart-img" :src="'https://tradersr.com' + slotProps.data.meigaras[0].meigara_trendchart_file"/>
												</router-link>
											</td>
											<td class="rotate_text"  vAlign="top" style="padding:0">
												<span class="date-span">{{getTrendchartDate(slotProps.data.meigaras[0].meigara_trendchart_edate)}}</span>
											</td>
										</tr>
									</table>
									<!-- <a href="#" @click.prevent.stop="onShow(slotProps.data)"><span class="span-highlight">...more</span></a>				 -->
									<div class="flex align-items-center justify-content-between"> 
										<span class="subinfo-span">株価@{{slotProps.data.odate}} {{slotProps.data.otime}}</span>
										<a href="#" @click.prevent.stop="onShow(slotProps.data)">
											<span class="subinfo-span">
												{{slotProps.data.meigaras_cnt}}銘柄 
												<!-- <i class="pi pi-window-maximize" @click="copyToClipboard(slotProps.data.meigaras)"></i> -->
												<i class="pi pi-window-maximize"></i>
											</span>
										</a>
									</div>
								</div>
							</div>
							
							<div v-else-if="slotProps.data.data_type == 'dummy'" class="card m-1 border-0" align="center">
									キーワード
									<Chip class="chip-r chip-highlight">
										<span v-if="clickedObject == 'sentiment'">
											<span v-if="selectedCodeKey == 'P'">好</span>
											<span v-else-if="selectedCodeKey == 'N'">悪</span>
											<span v-else-if="selectedCodeKey == 'B'">好/悪</span>
										</span>
										<span v-else>
											{{selectedCodeKey}}
										</span>
									</Chip>

									<div v-if="clickedObject != 'all'">
										<div v-if="incrementalDone == 'false'">
											<Button label="ニュースをもっと見る" icon="pi pi-refresh" :model="button_items" class="p-button-text" @click="getIncrementalNews" :loading="loading_more"/>
										</div>
										<div v-if="incrementalDone == 'true'">
											<span class="subinfo-span">End of News</span>
										</div>
									</div>
							</div>

						</div>
					</template>
				</DataView>
		</div>

	</div>
</template>

<script>
	import MeigaranewsrecentlService_quick from "../service/MeigaranewsrecentlService_quick";
	import News from '@/components/News';

	export default {
		data() {
			return {
				copiedMeigaras: null,
				// bookmarks: null,
				selectedCodeKey: 0,
				// selectedNewsType: null,
				meigaraKey: null,
				mcd: null,
        perf: null,
				dataviewValue: null,
				dataviewValueAll: null,
				layout: 'grid', // g(grid) or l(list)
				sortKey: null,
				sortOrder: null,
				sortField: null,
				clipboardText: null,
				clickedNews: null,
				clickedObject: null,
				codeKey: 0,
				selectedNewsId: null,
				loading: false,
				loading_more: false,
				lastnews_datetime: false,
				firstnews_datetime: false,
				incrementalCount: 0,
				incrementalDone: null,
				lastload_type: null,
				lastload_codekey: null,
				lastload_incrementalDone: null,
				recent_flag: false,
				offset_dt_sent: null,
				sortOptions: [
					{label: 'Price High to Low', value: '!price'},
					{label: 'Price Low to High', value: 'price'},
				],
				items: [
					{
						label: '銘柄をコピー',
						icon: 'pi pi-copy',
						command: () => {
							// this.$router.push('/meigarasearch_ext/' + this.selectedBookmarkCd);
							this.copyToClipboard(this.copiedMeigaras);
						}
					},
					{
						label: '前日比で昇順',
						icon: 'pi pi-sort-numeric-up-alt',
						command: () => {
							// console.log(this.selectedNewsId);
							this.sortMeigaras(0, this.selectedNewsId);
						}
					},
					{
						label: '前日比で降順',
						icon: 'pi pi-sort-numeric-down-alt',
						command: () => {
							// console.log(this.selectedNewsId);
							this.sortMeigaras(1, this.selectedNewsId);
						}
					},
				],
				button_items: [
					{
						label: 'リスト表示',
						icon: 'pi pi-bars',
						command: () => {
							this.layout = 'list';
						}
					},
					{
						label: 'グリッド表示',
						icon: 'pi pi-th-large',
						command: () => {
							this.layout = 'grid';
						}
					},
				]
			}
		},
		meigaranewsrecentlService_quick: null,
		created() {
			this.meigaranewsrecentlService_quick = new MeigaranewsrecentlService_quick();
		},
		mounted() {
			this.layout  = this.$route.params.code.substring(0, 4);

			this.loading = true;
			this.meigaranewsrecentlService_quick.getNewsInfo(this.$route.params.code)
				.then(d => {
					// this.bookmarks = d.data.bookmarks;
					this.dataviewValue = d.data.newslist;
					this.dataviewValueAll = d.data.newslist;
					this.firstnews_datetime = d.data.newslist_firstnews_datetime;
					this.lastnews_datetime = d.data.newslist_lastnews_datetime;
					this.perf = d.perf;
					this.selectedCodeKey = this.$route.params.code.substring(5, this.$route.params.code.length);

					this.loading = false;
					this.loading_more = false;

					// this.selectedCodeKey = 0;
					this.selectedCodeKey = 'All';

					this.incrementalCount = 0;
					this.incrementalDone = 'false';
					this.lastload_type = 'a';
					this.lastload_codekey = '0';
					this.lastload_incrementalDone = 'false';
					this.recent_flag = true;

					console.log(this.firstnews_datetime);
					console.log(this.lastnews_datetime);
				}
			);
			// this.clickedObject = 'button';
			// this.selectedCodeKey = 0;
			this.clickedObject = 'all';
			// this.selectedCodeKey = 'All';
		},
		methods: {
			onSortChange(event){
				const value = event.value.value;
				const sortValue = event.value;

				if (value.indexOf('!') === 0) {
					this.sortOrder = -1;
					this.sortField = value.substring(1, value.length);
					this.sortKey = sortValue;
				}
				else {
					this.sortOrder = 1;
					this.sortField = value;
					this.sortKey = sortValue;
				}
			},
			getChipClass(key){
				return [
					{'a-active':this.selectedCodeKey===key},
				]
			},
			getChipTextClass(key){
				return [
					{'text-active':this.selectedCodeKey===key},
				]
			},
			getNewstagClass(key){
				return [
					{'a-active':this.selectedCodeKey===key},
				]
			},
			getNewstagTextClass(key){
				return [
					{'active':this.selectedCodeKey===key},
				]
			},
			getBadgeTextClass_news(key){
				return [
					{'text-active':this.selectedCodeKey===key},
				]
			},
			getBadgeStatusText(key){
				let ret = null;

				if(this.selectedCodeKey===key){
					ret = 'active';
				}else{
					ret = key.toLowerCase();
				}

				return ret;
			},
			getTagStatusText(key){
				let ret = null;

				if(this.selectedCodeKey===key){
					ret = 'active';
				}else{
					ret = 'default';
				}

				return ret;
			},
			getPriceClass(data) {
				return [
					{
						'positive': data > 0,
						'negative': data < 0,
						'neutral': data == 0,
					}
				];
      },
			getFaceSymbol(sentiment) {
				let ret = null;

				if(sentiment == 'P'){
					ret = '好';
				}else if(sentiment == 'N'){
					ret = '悪';
				}else if(sentiment == 'B'){
					ret = '？';
				}else{
					ret = '未';
				}

				return ret;
			},
			getTrendchartDate(param) {
				let tcdate = null;
				if(param.length == 10){
					tcdate = param.substr(5, 5);
					tcdate = tcdate.replace('-','/');
				}
				return tcdate;
			},
			onSearchClick() {
				if(this.meigaraKey && this.meigaraKey.length > 1) {
					let code = this.layout + 'm' + this.meigaraKey;   
					this.meigaranewsrecentlService_quick.getNewsInfo(code)
					.then(d => {
						// this.bookmarks = d.data.bookmarks;
						this.dataviewValue = d.data.newslist;
						this.dataviewValueAll = d.data.newslist;
						// this.lastnews_datetime = d.data.newslist_lastnews_datetime;
						this.perf = d.perf;
					}
					);	
					// this.currentMeigaraKey = this.meigaraKey;
					this.selectedCodeKey = this.meigaraKey;
					this.meigaraKey = null;
				}
			},
			gotoMeigarachart(event, mcd) {
				this.$router.push('/meigarachart/' + mcd);
				event.preventDefault();
			},
			copyToClipboard(meigaras){
				// console.log(meigaras);
				this.clipboardText = '';
				for(let i=0; i<meigaras.length; i++) {
					if(i != meigaras.length-1){
						this.clipboardText += meigaras[i].meigara_cd + ", ";
					}else{
						this.clipboardText += meigaras[i].meigara_cd;
					}
				}
				// console.log(this.clipboardText);
				navigator.clipboard.writeText(this.clipboardText);
				// console.log("copied");
				this.$toast.add({severity:'success', summary: '銘柄コピー', detail:'クリップボードに' + meigaras.length + '銘柄をコピーしました (' + this.clipboardText + ')', life: 2000});
			},
			toggle_setMeigaras(event, meigaras, id){
				console.log(id);
				this.$refs.menu1.toggle(event);
				this.copiedMeigaras = meigaras;
				this.selectedNewsId = id;
			},
			onShow(params) {
				this.clickedNews = params;
				console.log(this.clickedNews);

				this.$dialog.open(News, {
					props: {
						header: 'News Insight',
						style: {
								// width: '50vw',
								width: '75vw',
								// height: '90vw',
								// background: '#FDFEFF'
						},
						breakpoints:{
								'960px': '75vw',
								'640px': '90vw'
						},
						modal: true,
						dismissableMask: true
					},
					data: {
						newsData: this.clickedNews ? this.clickedNews : null,
						selectedCodeKey: this.selectedCodeKey ? this.selectedCodeKey : 0,
						layout: this.layout ? this.layout : null,
						caller: 'meigaranewsrecentl_quick',
						selectedNewsId: this.selectedNewsId ? this.selectedNewsId : null,
					}
				});			
			},
			onSentimentClick(sentiment){
				if(this.selectedCodeKey != sentiment){
					let newslist = [];
					console.log(this.dataviewValueAll);
					for (let i=0; i<this.dataviewValueAll.length; i++){
						if(this.dataviewValueAll[i].news_sentiment == sentiment || this.dataviewValueAll[i].news_type == null){
							newslist.push(this.dataviewValueAll[i]);
						}
					}
					this.dataviewValue = newslist;
					this.selectedCodeKey = sentiment;
					this.perf = 'local';
					this.clickedObject = 'sentiment';

					this.incrementalCount = 0;

					if (this.lastload_codekey == this.selectedCodeKey && this.lastload_incrementalDone == 'true') {
						this.incrementalDone = 'true';
					}else{
						this.incrementalDone = 'false';
					}

					window.scrollTo(0,0);
				}
			},
			onNewsTypeClick(type){
				if(this.selectedCodeKey != type){
					let newslist = [];
					console.log(this.dataviewValueAll);
					for (let i=0; i<this.dataviewValueAll.length; i++){
						if(this.dataviewValueAll[i].news_type == type || this.dataviewValueAll[i].news_type == null){
							newslist.push(this.dataviewValueAll[i]);
						}
					}
					this.dataviewValue = newslist;
					this.selectedCodeKey = type;
					this.perf = 'local';
					this.clickedObject = 'newstype';

					this.incrementalCount = 0;
					// this.incrementalDone = 'false';

					if (this.lastload_codekey == this.selectedCodeKey && this.lastload_incrementalDone == 'true') {
						this.incrementalDone = 'true';
					}else{
						this.incrementalDone = 'false';
					}
					
					window.scrollTo(0,0);
				}
			},
			onTagClick(tag){
				if(this.selectedCodeKey != tag){
					console.log('this.selectedCodeKey != tag');

					let newslist = [];
					console.log(this.dataviewValueAll);
					for (let i=0; i<this.dataviewValueAll.length; i++){
						for (let j=0; j<this.dataviewValueAll[i].news_tags.length; j++){
							if(this.dataviewValueAll[i].news_tags[j] == tag || this.dataviewValueAll[i].news_tags[j] == 'dummy'){
								newslist.push(this.dataviewValueAll[i]);
								break;
							}
						}
					}
					this.dataviewValue = newslist;
					this.selectedCodeKey = tag;
					this.perf = 'local';
					this.clickedObject = 'tag';

					this.incrementalCount = 0;

					if (this.lastload_codekey == this.selectedCodeKey && this.lastload_incrementalDone == 'true') {
						this.incrementalDone = 'true';
					}else{
						this.incrementalDone = 'false';
					}

					window.scrollTo(0,0);
				}
			},
			onMeigaraClick(mcd){
				if(this.selectedCodeKey != mcd){
					let newslist = [];
					console.log(this.dataviewValueAll);
					for (let i=0; i<this.dataviewValueAll.length; i++){
						for (let j=0; j<this.dataviewValueAll[i].meigaras.length; j++){
							if(this.dataviewValueAll[i].meigaras[j].meigara_cd == mcd || this.dataviewValueAll[i].meigaras[j] == 'dummy'){
								newslist.push(this.dataviewValueAll[i]);
								break;
							}
						}
					}
					this.dataviewValue = newslist;
					this.selectedCodeKey = mcd;
					this.perf = 'local';
					this.clickedObject = 'meigara';

					window.scrollTo(0,0);

					this.incrementalCount = 0;

					if (this.lastload_codekey == this.selectedCodeKey && this.lastload_incrementalDone == 'true') {
						this.incrementalDone = 'true';
					}else{
						this.incrementalDone = 'false';
					}
				}
			},
			onAllClick(){
				if(this.selectedCodeKey != 'All'){
					this.dataviewValue = this.dataviewValueAll;		
					this.selectedCodeKey = 'All';	

					console.log(this.dataviewValueAll);

					window.scrollTo(0,0);
				}
				this.perf = 'local';
				this.clickedObject = 'all';
			},
			onRefreshButtonClick(){
				//this.layout  = code.substring(0, 4);

				// if(this.clickedObject != 'button'){

					// this.dataviewValue = [];
					this.loading_more = false;

					this.loading = true;
					this.meigaranewsrecentlService_quick.getNewsInfo('grida0')
						.then(d => {
							// this.bookmarks = d.data.bookmarks;
							this.dataviewValue = d.data.newslist;
							this.dataviewValueAll = d.data.newslist;
							this.firstnews_datetime = d.data.newslist_firstnews_datetime;
							this.lastnews_datetime = d.data.newslist_lastnews_datetime;
							this.perf = d.perf;
							// this.selectedCodeKey = 0;
							this.selectedCodeKey = 'All';
							this.incrementalCount = 0;
							this.incrementalDone = 'false';
							this.lastload_type = 'a';
							this.lastload_codekey = '0';
							this.lastload_incrementalDone = 'false';
							this.recent_flag = true;

							this.loading = false;

							console.log(this.dataviewValueAll);
							console.log(this.firstnews_datetime);
							console.log(this.lastnews_datetime);
						}
					);

					this.clickedObject = 'all';

				// }
			},
			sortMeigaras(sortOption, newsId){
				console.log(sortOption);
				console.log(newsId);
				
				let idx = this.findNewsIndex(newsId);
				let meigaras = this.dataviewValue[idx].meigaras;

				if(sortOption == 0){
					meigaras.sort((a, b) => {
						return a.v1d_mean < b.v1d_mean ? -1 : 1;
					});
				}else{
					meigaras.sort((a, b) => {
						return a.v1d_mean > b.v1d_mean ? -1 : 1;
					});
				}

				this.dataviewValue[idx].meigaras = meigaras;
			},
			findNewsIndex(newsId){
				let idx = null;
				for (let i=0; i<this.dataviewValue.length; i++){
					if(this.dataviewValue[i].id == newsId){
						idx = i;
						break;
					}
				}
				return idx;
			},
			onDataLoad(){
				console.log('onDataLoad function called');
				// this.getIncrementalNews();
			},
			getIncrementalNews(){
				this.loading_more = true;

				let offset_dt = null;
				let current_dt = new Date();
				let first_inc = null;
				let lastnews_dt = null;

				let current_datetime = current_dt.getFullYear() + '/'
											+ (current_dt.getMonth() + 1) + '/'
											+ current_dt.getDate() + ' '
											+ current_dt.getHours() + ':'
											+ current_dt.getMinutes();

				if(this.incrementalCount == 0){
					// offset_dt = this.firstnews_datetime;
					// let current_dt = new Date();
					// this.firstnews_datetime = current_datetime;

					offset_dt = current_datetime;

					// offset_dt = new Date(current_datetime.replace('-','/'));
					first_inc = 'true';

					// let offset_dt = this.lastnews_datetime;
					let tmp_dt = new Date(this.lastnews_datetime.replace(/-/g,'/'));
					tmp_dt.setMinutes(tmp_dt.getMinutes() - 1);
					lastnews_dt = tmp_dt.getFullYear() + '/'
											+ (tmp_dt.getMonth() + 1) + '/'
											+ tmp_dt.getDate() + ' '
											+ tmp_dt.getHours() + ':'
											+ tmp_dt.getMinutes();

				}else{
					// offset_dt = this.lastnews_datetime;

					offset_dt = this.lastnews_datetime;
					let tmp_dt = new Date(offset_dt.replace(/-/g,'/'));
					tmp_dt.setMinutes(tmp_dt.getMinutes() - 1);
					offset_dt = tmp_dt.getFullYear() + '/'
											+ (tmp_dt.getMonth() + 1) + '/'
											+ tmp_dt.getDate() + ' '
											+ tmp_dt.getHours() + ':'
											+ tmp_dt.getMinutes();
					
					first_inc = 'false';
				}
				console.log('step1:' + offset_dt);

				let type = null;
				if(this.clickedObject == 'meigara'){
					type = 'm';
				}else if(this.clickedObject == 'newstype'){
					type = 't';
				}else if(this.clickedObject == 'tag'){
					type = 'z';
				}else if(this.clickedObject == 'sentiment'){
					type = 's';
				}else if(this.clickedObject == 'all'){
					type = 'a';
				}

				// console.log(offset_dt);

				this.offset_dt_sent = offset_dt;

				let code = this.layout + type + this.selectedCodeKey;   
				this.meigaranewsrecentlService_quick.getNewsInfo(code, offset_dt, first_inc, lastnews_dt)
				.then(d => {
					// let tmpArray = d.data.newslist;
					// this.dataviewValue.concat(tmpArray);
					// this.dataviewValueAll = this.dataviewValue;

					if(this.incrementalCount == 0){
						this.dataviewValue = d.data.newslist;
						this.dataviewValueAll = this.dataviewValue;
					}else{
						this.dataviewValue.pop();
						let mergedValue = [...this.dataviewValue, ...d.data.newslist];
						this.dataviewValue = mergedValue;
						this.dataviewValueAll = mergedValue;
					}

					// this.firstnews_datetime = d.data.newslist_firstnews_datetime;
					this.lastnews_datetime = d.data.newslist_lastnews_datetime;
					this.perf = d.perf;

					this.incrementalCount++;

					console.log(this.dataviewValueAll);
					// console.log(this.firstnews_datetime);
					console.log(this.lastnews_datetime);
					console.log(d.data.newslist_incremental_done);

					// if (d.data.newslist_incremental_done == 'true'){
					// 	this.dataviewValue.pop();
					// }
					this.incrementalDone = d.data.newslist_incremental_done;
					if(this.incrementalDone == 'true'){
						this.incrementalCount = 0;
						this.lastload_incrementalDone = 'true';
					}

					this.loading_more = false;
					this.lastload_type = type;
					this.lastload_codekey = this.selectedCodeKey;

					this.recent_flag = false;

					// if(this.lastload_codekey == this.selectedCodeKey){
					// 	this.lastload_incrementalDone == 'true';
					// }
				});
			}
		}
	}
</script>

<style scoped lang="scss">
	@import '../assets/prod/styles/badges.scss';
	@import '../assets/prod/styles/prices.scss';

	.accordion-r {
    margin-bottom: 1rem
  }
  .chip-r {
    margin: 2px
  }

	a {
    color: #515c66;
    // color: #ffffff;
  }
  a:hover {
    color: #4291dc;
    // text-decoration:underline;
  }
  .a-active {
    background-color: rgba(47, 142, 229, 0.4);
  }
  .text-active {
    color: #ffffff;
  }
	.img_link {
		cursor: pointer;
	}

	i:hover {
		color: #4291dc;
		cursor: pointer;
	}

	.subinfo-span {
		// color: rgba(81, 92, 102, 0.6);
		font-size: 12px;
	}

	.trendchart-img {
		opacity: 0.8;
		// border: solid 1px #cccccc;
		border-radius: 5px;  
		// width: 182px;
		width: 137px;
		height: 47px;
	}

	.span-highlight{
		color: #4291dc;
	}
	.rotate_text {
		transform:rotate(-90deg);
	}
	.date-span {
		color: rgba(81, 92, 102, 0.6);
		font-size: 11px;    
	}
	// .trendchart-img2 {
	// 	opacity: 0.8;
	// 	// border: solid 1px #cccccc;
	// 	border-radius: 5px;  
	// 	width: 100%;
	// 	height: 100%;
	// }
	// .ui-paginator .ui-state-default {
  //   color:blue!important;
  // }

	.chip-highlight {
    background-color: rgba(2, 172, 172, 0.2);
    color: #02acac;
  }
	.chip-highlight2 {
    background-color: rgba(241, 99, 131, 0.2);
    color: #f16383;
  }
	.chip-highlight3 {
    background-color: #feedaf;
    color: #8a5340;
  }
</style>
